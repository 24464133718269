import styles from './Button.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'

interface Props {
    className?: Value
    large?: boolean
    type?: 'button' | 'submit' | 'reset'
    disabled?: boolean
    onClick?: () => void
}

const Button: React.FC<Props> = ({ children, className, disabled, type = 'button', large, onClick }) => {
    return (
        <button
            type={type}
            disabled={disabled}
            className={classnames(styles.container, className, {
                [styles.isLarge]: large,
                [styles.isDisabled]: disabled,
            })}
            onClick={() => {
                if (disabled) return

                onClick?.()
            }}
        >
            {children}
        </button>
    )
}

export default Button
