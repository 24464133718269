/* eslint-disable @next/next/no-img-element */
import styles from './NFTs.module.scss'

import React, { useEffect, useState } from 'react'
import classnames, { Value } from 'classnames'
import Card from '../Card/Card'
import { getNFTs, NFTData } from '../../utils/getNFTs'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import Loader from '../Loader/Loader'
import { PublicKey } from '@solana/web3.js'

interface Props {
    className?: Value
    onSelectedNFT?: (nft?: NFTData) => void
    excludeMints?: PublicKey[]
}

const NFTs: React.FC<Props> = ({ className, onSelectedNFT, excludeMints = [] }) => {
    const [nfts, setNFTs] = useState<NFTData[]>([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const { connection } = useConnection()
    const { publicKey, connected } = useWallet()
    const [selected, setSelected] = useState<number>(-1)

    const loadNFTs = async () => {
        if (!publicKey || !connected) return
        setLoading(true)

        try {
            setNFTs(await getNFTs(connection, publicKey))
        } catch (error) {
            setError(true)
        }

        setLoading(false)
    }

    useEffect(() => {
        loadNFTs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection, publicKey])

    const renderNFTs = () => {
        if (!connected) {
            return <>Connect your wallet to show your NFT collection.</>
        }

        if (error) {
            return <>Failed to load NFT collection from wallet. Please try again.</>
        }

        if (loading) {
            return (
                <div>
                    <Loader label="Loading NFTs..." />
                </div>
            )
        }

        if (nfts.length === 0) {
            return <>No Solice NFT collection found in your wallet.</>
        }

        return nfts
            .filter((nft) => !excludeMints.find((publicKey) => publicKey.toBase58() === nft.mint))
            .map((nft, index) => (
                <Card
                    key={index}
                    onClick={() => {
                        setSelected(index)
                        onSelectedNFT?.(nft)
                    }}
                    className={classnames(styles.card, { [styles.selectedCard]: selected === index })}
                >
                    {selected === index && (
                        <div
                            className={styles.close}
                            onClick={(e) => {
                                e.stopPropagation()
                                console.log('clicked')
                                setSelected(-1)
                                onSelectedNFT?.(undefined)
                            }}
                        >
                            X
                        </div>
                    )}
                    <div className={styles.name}>{nft.name}</div>
                    <div className={styles.symbol}>{nft.symbol}</div>
                    <div className={styles.image}>
                        <img src={nft.uri || '/images/unknown.png'} alt={nft.name} />
                    </div>
                    <div className={styles.multiplier}>
                        NFT multiplier{' '}
                        <span className={styles.number}>{nft.multiplier ? <>*{nft.multiplier * 100}%</> : '??'}</span>
                    </div>
                </Card>
            ))
    }

    return (
        <Card
            className={classnames(styles.container, className, {
                [styles.center]: loading || nfts.length === 0,
            })}
        >
            {renderNFTs()}
        </Card>
    )
}

export default NFTs
