import styles from './Modal.module.scss'

import React, { useCallback, useState } from 'react'
import classnames, { Value } from 'classnames'
import ReactModal from 'react-modal'

interface Props {
    className?: Value
    children: (openModal: () => void) => React.ReactNode
    modalContent: () => React.ReactNode
    onClose?: () => void
}

const Modal: React.FC<Props> = ({ className, children, onClose, modalContent }) => {
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = useCallback(() => {
        setIsOpen(false)
        onClose?.()
    }, [setIsOpen, onClose])

    const openModal = useCallback(() => {
        setIsOpen(true)
    }, [setIsOpen])

    return (
        <>
            {children(openModal)}
            <ReactModal
                overlayClassName={{
                    base: styles.overlay,
                    afterOpen: styles.afterOpen,
                    beforeClose: styles.beforeClose,
                }}
                className={{
                    base: classnames(styles.container, className),
                    afterOpen: styles.afterOpen,
                    beforeClose: styles.beforeClose,
                }}
                isOpen={isOpen}
                onRequestClose={closeModal}
                closeTimeoutMS={255}
            >
                <button type="button" className={styles.closeButton} onClick={closeModal}>
                    <svg
                        viewBox="0 0 512 512"
                        className={styles.closeIcon}
                        xmlSpace="preserve"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" />
                    </svg>
                </button>
                {modalContent()}
            </ReactModal>
        </>
    )
}

export default Modal
