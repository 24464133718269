/* eslint-disable @next/next/no-img-element */
import styles from './StakingCalculator.module.scss'
import BigDecimal from 'js-big-decimal'

import React, { useState, useCallback, useEffect } from 'react'
import classnames, { Value } from 'classnames'
import Button from '../Button/Button'
import NumberFormat from 'react-number-format'
import { addDays } from '../../utils/addDays'
import { formatDate } from '../../utils/intl'
import Card from '../Card/Card'
import SendTransaction from '../Wallet/SendTransaction'
import Modal from '../Modal/Modal'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import AnimatedNumber from '../AnimatedNumber/AnimatedNumber'
import { PublicKey } from '@solana/web3.js'
import { DECIMALS, SLC_MINT } from '../../utils/consts'

interface Props {
    className?: Value
    maxAvailableAmount?: number
    sendToWallet: PublicKey
    selectedNFTMint?: PublicKey
    onSuccess?: (nft?: PublicKey) => void
    multiplier?: number
}

const minValue = 0

const StakingCalculator: React.FC<Props> = ({
    className,
    sendToWallet,
    selectedNFTMint,
    maxAvailableAmount = 0,
    onSuccess,
    multiplier,
}) => {
    const { wallet } = useWallet()
    const { setVisible, visible } = useWalletModal()
    const [formattedTokenValue, setFormattedTokenValue] = useState('')
    const [tokenValue, setTokenValue] = useState<number>(0)
    const [result, setResult] = useState<number>(0)
    const stakeResultValue = tokenValue
    const apy = 0.4
    const lockTime = 365
    const lockReleasedAt = formatDate(addDays(new Date(), lockTime))
    const formattedAPY = `${apy * 100}%`
    const formattedMultiplier = `${(multiplier ?? 0) * 100}%`

    const setMaxTokenAmount = useCallback(() => {
        setTokenValue(maxAvailableAmount)
        setFormattedTokenValue(maxAvailableAmount.toString())
    }, [maxAvailableAmount])

    useEffect(() => {
        const stakeAPY = new BigDecimal(apy).multiply(new BigDecimal(1 + (multiplier ?? 0)))
        console.log(stakeAPY)
        const result = new BigDecimal(tokenValue).multiply(stakeAPY.add(new BigDecimal(1)))
        setResult(parseFloat(result.getValue()))
    }, [tokenValue, multiplier])

    return (
        <Card className={classnames(styles.container, className)}>
            <h3 className={styles.title}>SLC calculator</h3>
            <p className={styles.description}>
                Calculate your SLC depending on the amount of tokens and nft locked. deposit.
            </p>
            <div className={styles.inputContainer}>
                <NumberFormat
                    value={formattedTokenValue}
                    thousandSeparator={true}
                    className={styles.input}
                    isAllowed={(values) => {
                        const { floatValue = 0 } = values
                        return floatValue >= minValue
                    }}
                    onValueChange={(e) => {
                        setTokenValue(e.floatValue ?? 0)
                        setFormattedTokenValue(e.formattedValue)
                    }}
                    placeholder={'SLC amount'}
                />
                <button type="button" className={styles.inputMaxButton} onClick={setMaxTokenAmount}>
                    MAX
                </button>
            </div>
            <div className={styles.apyContainer}>
                <div className={styles.apy}>
                    <div>
                        <span className={styles.apyPercentage}></span>
                    </div>
                </div>
                <div className={styles.apy}>
                    <div>
                        <span className={styles.apyPercentage}>{formattedAPY}</span>
                        <span className={styles.apySuffix}>Base APY</span>
                    </div>
                    <div className={styles.lockTime}>locked for {lockTime} days</div>
                </div>
                <div className={styles.apy}>
                    <div>
                        <span className={styles.apyPercentage}>*{formattedMultiplier}</span>
                        <span className={styles.apySuffix}>Multiplier</span>
                    </div>
                </div>
                <div className={styles.seperator} />
                <div className={styles.apyResult}>
                    <span className={styles.apyResultLabel}>Stake</span>
                    <AnimatedNumber className={styles.apyResultValue} value={stakeResultValue} />
                </div>
                <div className={styles.apyResult}>
                    <span className={styles.apyResultLabel}>Return</span>
                    <AnimatedNumber className={styles.apyResultValue} value={result} />
                </div>
            </div>
            <Modal
                modalContent={() => {
                    return (
                        <>
                            <h5 className={styles.successModalTitle}>Thank you for staking your tokens.</h5>
                            <p className={styles.successModalDescription}>
                                After {lockTime} days your tokens and NFT will be unlocked and deposited back to your
                                wallet.
                            </p>
                            <div className={styles.successModalLogo}>
                                <img
                                    src={'/images/logo_name_white.png'}
                                    height={48}
                                    width={100}
                                    style={{ objectFit: 'contain' }}
                                    alt="Solice"
                                />
                            </div>
                        </>
                    )
                }}
            >
                {(openModal) => (
                    <SendTransaction>
                        {(sendTransaction) => (
                            <Button
                                className={styles.button}
                                large
                                disabled={wallet && !tokenValue ? true : false}
                                onClick={async () => {
                                    if (!wallet) {
                                        setVisible(!visible)
                                        return
                                    }

                                    if (!sendToWallet) return

                                    const transfers = [
                                        { wallet: sendToWallet, mint: SLC_MINT, amount: tokenValue * DECIMALS },
                                    ]

                                    if (selectedNFTMint) {
                                        transfers.push({
                                            wallet: sendToWallet,
                                            mint: selectedNFTMint,
                                            amount: 1,
                                        })
                                    }

                                    const result = await sendTransaction(transfers)

                                    if (result) {
                                        openModal()
                                        setTokenValue(0)
                                        setFormattedTokenValue('0')
                                        onSuccess?.(selectedNFTMint)
                                    }
                                }}
                            >
                                {!wallet ? 'Connect Wallet' : 'Stake SLC'}
                            </Button>
                        )}
                    </SendTransaction>
                )}
            </Modal>
            <div className={styles.lockRelease}>locked until {lockReleasedAt}</div>
        </Card>
    )
}

export default StakingCalculator
