export const locale = 'en-US'

export const formatDate = (date: Date) => new Intl.DateTimeFormat(locale).format(date)

export const formatNumber = (value: number) => {
    if (value <= 0.00001) {
        return new Intl.NumberFormat(locale, { maximumFractionDigits: 9, minimumFractionDigits: 0 }).format(value)
    }
    if (value <= 0.0001) {
        return new Intl.NumberFormat(locale, { maximumFractionDigits: 6, minimumFractionDigits: 0 }).format(value)
    }

    return new Intl.NumberFormat(locale, { maximumFractionDigits: 4, minimumFractionDigits: 0 }).format(value)
}
