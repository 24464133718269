import { ConfirmedSignatureInfo, Connection, PublicKey } from '@solana/web3.js'
import { DECIMALS } from './consts'
import Sentry from '@sentry/nextjs'
export interface DepositInfo {
    total: number
    transactions: Transaction[]
}

export interface Transaction {
    date: Date
    total: number
}

export interface FormattedTransaction {
    signature: string
    mint: string
    time: Date
    sender: {
        owner: string
        amount: number
    }
    receiver: {
        owner: string
        amount: number
    }
    name?: string
    symbol?: string
}

function* generateChunks<TArray>(array: TArray[], size: number) {
    let start = 0
    while (start < array.length) {
        yield array.slice(start, start + size)
        start += size
    }
}

const may15 = 1652572800000

export const getTransactionsToWallet = async (connection: Connection, wallet: PublicKey, toWallet: PublicKey) => {
    let signatures: ConfirmedSignatureInfo[] = []

    let lastSignature
    // eslint-disable-next-line no-constant-condition
    while (true) {
        console.log(lastSignature)
        const sigs: ConfirmedSignatureInfo[] = await connection.getSignaturesForAddress(wallet, {
            limit: 1000,
            before: lastSignature?.signature,
        })
        lastSignature = sigs[sigs.length - 1]
        signatures = [...signatures, ...sigs]
        if (new Date((lastSignature?.blockTime ?? 0) * 1000).getTime() <= may15) {
            break
        }
    }

    const chunked = [...generateChunks(signatures, 200)]
    const formatted: FormattedTransaction[] = []
    for (const chunks of chunked) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const transactions: any[] = await connection.getParsedConfirmedTransactions(chunks.map((sig) => sig.signature))

        const filtered = transactions.filter((transaction) => {
            const preFrom = transaction?.meta?.preTokenBalances[0]
            const preTo = transaction?.meta?.preTokenBalances[1]
            return (
                (preFrom?.owner === wallet.toBase58() && preTo?.owner === toWallet.toBase58()) ||
                (preTo?.owner === wallet.toBase58() && preFrom?.owner === toWallet.toBase58())
            )
        })

        for (const transaction of filtered) {
            const preFrom = transaction?.meta?.preTokenBalances[0]
            const preTo = transaction?.meta?.preTokenBalances[1]
            const postFrom = transaction?.meta?.postTokenBalances[0]
            const postTo = transaction?.meta?.postTokenBalances[1]

            const preData = {
                owner: preFrom.owner,
                amount: postFrom.uiTokenAmount.uiAmount - preFrom.uiTokenAmount.uiAmount,
            }

            const postData = {
                owner: preTo.owner,
                amount: postTo.uiTokenAmount.uiAmount - preTo.uiTokenAmount.uiAmount,
            }

            let sender
            let receiver
            if (preData.owner === toWallet.toBase58()) {
                sender = postData
                receiver = preData
            } else if (postData.owner === toWallet.toBase58()) {
                sender = preData
                receiver = postData
            } else {
                try {
                    console.log('Failed to determine sender and reciever for staking transaction', transaction)

                    Sentry.captureMessage('Failed to determine sender and reciever for staking transaction', {
                        extra: {
                            data: JSON.stringify(transaction, null, 2),
                        },
                    })
                } catch (error) {
                    console.error('failed to report to sentry', error)
                }
                continue
            }

            formatted.push({
                signature: transaction.transaction.signatures[0],
                mint: preFrom.mint,
                time: new Date(transaction.blockTime * 1000),
                sender,
                receiver,
            })
        }

        const mints = Array.from(new Set(formatted.map((formatted) => formatted.mint)))
        const mintInfo: { [mint: string]: { name: string; symbol: string } } = {}
        for (const mint of mints) {
            if (mint.startsWith('META')) continue
            try {
                const result = await (
                    await fetch(`https://public-api.solscan.io/token/meta?tokenAddress=${mint}`)
                ).json()
                mintInfo[mint] = result
            } catch (error) {
                console.error('failed to get token info', error)
            }
        }

        for (const transaction of formatted) {
            if (mintInfo[transaction.mint]) {
                transaction.name = mintInfo[transaction.mint].name
                transaction.symbol = mintInfo[transaction.mint].symbol
            }
        }
    }

    return formatted
}

export const getTokenTransactionsForWallet = async (
    connection: Connection,
    tokenAddress: PublicKey,
    from: PublicKey,
    to: PublicKey
) => {
    // Realisticly a user won't have 1000 transactions on the token address when we launch this
    // but this might be improved by adding some pagination.
    const signatures = await connection.getSignaturesForAddress(tokenAddress, { limit: 1000 })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transactions: any[] = await connection.getParsedConfirmedTransactions(signatures.map((sig) => sig.signature))

    const sentAmounts = []
    let totalSent = 0
    for (const transaction of transactions) {
        const preFrom = transaction?.meta?.preTokenBalances[0]
        const preTo = transaction?.meta?.preTokenBalances[1]

        if (preFrom?.owner !== from.toBase58() || preTo?.owner !== to.toBase58()) {
            // possibly back transaction, or transaction to another wallet, so we skip
            continue
        }

        const postFrom = transaction?.meta?.postTokenBalances[0]
        const preAmount = preFrom?.uiTokenAmount.amount
        const postAmount = postFrom?.uiTokenAmount.amount
        const amount = Math.abs((postAmount - preAmount) / DECIMALS)
        totalSent += amount

        sentAmounts.push({
            date: new Date(transaction?.blockTime * 1000),
            total: amount,
        })
    }

    const result = {
        total: totalSent,
        transactions: sentAmounts,
    }

    return result
}
