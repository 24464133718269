import { WalletNotConnectedError } from '@solana/wallet-adapter-base'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { Transaction, PublicKey } from '@solana/web3.js'
import React, { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { Token, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { getOrCreateAssociatedTokenAccount } from '../../utils/getOrCreateAssociatedTokenAccount'

interface Props {
    children: (sendTransaction: OnSendTransaction) => React.ReactNode
}

type OnSendTransaction = (transfers: { wallet: PublicKey; mint: PublicKey; amount: number }[]) => Promise<boolean>

// Docs: https://github.com/solana-labs/solana-program-library/pull/2539/files
// https://github.com/solana-labs/wallet-adapter/issues/189
// repo: https://github.com/solana-labs/example-token/blob/v1.1/src/client/token.js
// creating a token for testing: https://learn.figment.io/tutorials/sol-mint-token
const SendTransaction: React.FC<Props> = ({ children }) => {
    const { connection } = useConnection()
    const { publicKey, signTransaction, signAllTransactions } = useWallet()

    const onSendSPLTransaction = useCallback(
        async (transfers: { wallet: PublicKey; mint: PublicKey; amount: number }[]) => {
            if (!transfers.length) return false

            const toastId = toast.loading('Processing transaction...')

            try {
                if (!publicKey || !signAllTransactions) throw new WalletNotConnectedError()

                const transactions = []
                for (const transfer of transfers) {
                    const toPublicKey = transfer.wallet

                    if (!transfer.amount) {
                        continue
                    }

                    const tokenTransaction = await createTransaction(toPublicKey, transfer.mint, transfer.amount)
                    transactions.push(tokenTransaction)
                }

                const signedTransactions = await signAllTransactions(transactions)
                const signatures = []
                for (const signed of signedTransactions) {
                    signatures.push(await connection.sendRawTransaction(signed.serialize()))
                }

                await Promise.all(signatures.map((sig) => connection.confirmTransaction(sig)))

                toast.success('Transaction sent', {
                    id: toastId,
                })
                return true
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                toast.error(`Transaction failed: ${error.message}`, {
                    id: toastId,
                })
                return false
            }

            async function createTransaction(toPublicKey: PublicKey, mint: PublicKey, amount: number) {
                if (!publicKey || !signTransaction) throw new Error('No public key')

                const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
                    connection,
                    publicKey,
                    mint,
                    publicKey,
                    signTransaction
                )

                const toTokenAccount = await getOrCreateAssociatedTokenAccount(
                    connection,
                    publicKey,
                    mint,
                    toPublicKey,
                    signTransaction
                )

                const transaction = new Transaction().add(
                    Token.createTransferInstruction(
                        TOKEN_PROGRAM_ID,
                        fromTokenAccount.address,
                        toTokenAccount.address,
                        publicKey,
                        [],
                        amount
                    )
                )

                const blockHash = await connection.getRecentBlockhash()
                transaction.feePayer = await publicKey
                transaction.recentBlockhash = await blockHash.blockhash
                return transaction
            }
        },
        [publicKey, signAllTransactions, connection, signTransaction]
    )

    return <>{children(onSendSPLTransaction)}</>
}

export default SendTransaction
