import React, { useCallback, useEffect, useRef, useState } from 'react'
import classnames, { Value } from 'classnames'
import anime from 'animejs'
import { formatNumber } from '../../utils/intl'
import { debounce } from 'lodash'

interface Props {
    className?: Value
    value: number
}

const AnimatedNumber: React.FC<Props> = ({ className, value }) => {
    const ref = useRef<HTMLSpanElement>(null)
    const [lastValue, setLastValue] = useState(0)

    const animate = () => {
        if (!ref.current) return
        anime({
            targets: ref.current,
            innerText: [lastValue, value],
            easing: 'easeOutQuad',
            round: value <= 1000 ? 100 : true,
            duration: 500,
            update: function (a) {
                const value = parseFloat(a.animations[0].currentValue)
                const formattedNumber = formatNumber(value)
                if (!ref.current) return
                ref.current.innerHTML = formattedNumber
                setLastValue(value)
            },
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedAnimate = useCallback(debounce(animate, 500), [lastValue, value])

    useEffect(() => {
        debouncedAnimate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <span className={classnames(className)} ref={ref}>
            0
        </span>
    )
}

export default AnimatedNumber
