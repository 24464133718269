import styles from './Deposits.module.scss'

import React, { useEffect, useState } from 'react'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { FormattedTransaction, getTransactionsToWallet } from '../../utils/getTokenTransactionsForWallet'
import Link from 'next/link'
import { formatDate } from '../../utils/intl'
import Card from '../Card/Card'
import Loader from '../Loader/Loader'
import { STAKING_WALLET_ADDRESS } from '../../utils/consts'
import Sentry from '@sentry/nextjs'
import toast from 'react-hot-toast'

const Deposits: React.FC<{ className: string }> = ({ className }) => {
    const { connection } = useConnection()
    const { publicKey, connected } = useWallet()

    const [loading, setLoading] = useState(false)

    const [transactions, setTransactions] = useState<FormattedTransaction[]>([])

    const loadTransactions = async () => {
        if (!publicKey) return

        setLoading(true)
        try {
            const transactions = await getTransactionsToWallet(connection, publicKey, STAKING_WALLET_ADDRESS)
            setTransactions(transactions)
        } catch (error) {
            console.error(error)
            toast.error('Failed to fetch deposit history, please try again')
            Sentry.captureException(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        loadTransactions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publicKey, connected])

    return (
        <Card className={className}>
            <div className={styles.title}>Deposits</div>

            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.deposits}>
                        {transactions.map((transaction) => {
                            return (
                                <div key={transaction.signature}>
                                    <Link href={`https://solscan.io/tx/${transaction.signature}`}>
                                        <a className={styles.deposit}>
                                            <span>
                                                +{transaction.receiver.amount}{' '}
                                                {transaction.mint.startsWith('META')
                                                    ? 'SLC'
                                                    : transaction.name
                                                    ? transaction.name
                                                    : 'NFT'}
                                            </span>
                                            <span>{formatDate(transaction.time)}</span>
                                        </a>
                                    </Link>
                                </div>
                            )
                        })}
                        <div className={styles.help}>New staking deposits might take some time to show</div>
                    </div>
                </>
            )}
        </Card>
    )
}

export default Deposits
