import styles from './index.module.scss'

import React, { useState } from 'react'
import type { NextPage } from 'next'
import Head from 'next/head'
import StakingCalculator from '../components/StakingCalculator/StakingCalculator'
import NFTs from '../components/NFTs/NFTs'
import { useTokenBalance } from '../components/Wallet/useTokenBalance'
import { NFTData } from '../utils/getNFTs'
import { STAKING_WALLET_ADDRESS } from '../utils/consts'
import { PublicKey } from '@solana/web3.js'
import Link from 'next/link'
import Deposits from '../components/Deposits/Deposits'

const Index: NextPage = () => {
    const balance = useTokenBalance()
    const [selectedNFT, setSelectedNFT] = useState<NFTData>()
    const [sentNFTs, setSentNFTs] = useState<PublicKey[]>([])

    return (
        <>
            <Head>
                <title>Solice Staking</title>
            </Head>
            <div className={styles.description}>
                Stake your $SLC with a base apy of 40% and increase your apy by adding a nft to your stake! Please check
                out{' '}
                <Link href={'https://medium.com/@solice_io/solice-asset-staking-portal-released-a507dd1a9a13'}>
                    our article
                </Link>{' '}
                for more information.
            </div>
            <div className={styles.container}>
                <div>
                    <StakingCalculator
                        className={styles.calculator}
                        sendToWallet={STAKING_WALLET_ADDRESS}
                        selectedNFTMint={selectedNFT?.mint ? new PublicKey(selectedNFT?.mint) : undefined}
                        maxAvailableAmount={balance}
                        multiplier={selectedNFT?.multiplier}
                        onSuccess={(nft) => {
                            if (!nft) {
                                return
                            }

                            setSelectedNFT(undefined)
                            setSentNFTs(Array.from(new Set([...sentNFTs, nft])))
                        }}
                    />
                    <div className={styles.helpText}>
                        All staking deposits are final and cannot be changed. You can deposit multiple times which will
                        count as different stakes.
                    </div>
                    <Deposits className={styles.deposits} />
                </div>
                <NFTs excludeMints={sentNFTs} onSelectedNFT={setSelectedNFT} />
            </div>
        </>
    )
}

export default Index
