import styles from './Loader.module.scss'

import React, { useEffect, useState } from 'react'
import classnames, { Value } from 'classnames'

interface Props {
    className?: Value
    label?: string
}

const Loader: React.FC<Props> = ({ className, label }) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setShow(true)
        }, 500)
        return () => window.clearTimeout(timer)
    }, [])

    return (
        <div
            className={classnames(styles.container, className, {
                [styles.shown]: show,
            })}
        >
            <div className={styles.loader}>
                <div />
                <div />
                <div />
                <div />
            </div>
            <div>{label}</div>
        </div>
    )
}

export default Loader
