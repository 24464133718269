import styles from './Card.module.scss'

import React from 'react'
import classnames, { Value } from 'classnames'
import Link from 'next/link'

interface Props {
    className?: Value
    href?: string
    onClick?: () => void
    padding?: number
}

const Card: React.FC<Props> = ({ className, href, onClick, children, padding }) => {
    if (href) {
        return (
            <Link href={href}>
                <a
                    className={classnames(styles.container, className, {
                        [styles.isLink]: !!href,
                    })}
                    style={{ padding }}
                >
                    {children}
                </a>
            </Link>
        )
    }

    if (onClick) {
        return (
            <button
                type="button"
                className={classnames(styles.container, className, styles.hasOnClick)}
                onClick={onClick}
                style={{ padding }}
            >
                {children}
            </button>
        )
    }

    return (
        <div className={classnames(styles.container, className)} style={{ padding }}>
            {children}
        </div>
    )
}

export default Card
