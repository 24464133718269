import { Connection, PublicKey } from '@solana/web3.js'
import { getParsedNftAccountsByOwner } from '@nfteyez/sol-rayz'
import { nftMultipliers, nftSymbols } from './consts'

export interface NFTData {
    name: string
    symbol: string
    uri?: string
    multiplier?: number
    mint: string
}

function getMultiplier(nft: any, metaData: any) {
    const multipliers = Object.entries(nftMultipliers)

    const foundInName = multipliers.find(
        ([key]) =>
            key.toLowerCase() === nft.data.symbol.toLowerCase() ||
            key.toLocaleLowerCase() === nft.data.name.toLowerCase()
    )

    if (foundInName) {
        return foundInName[1]
    }

    const attributes = metaData.attributes.map((attr: any) => attr.value)
    const attr = attributes.find((attr: string) =>
        multipliers.find(([key]) => key.toLowerCase() === attr.toLowerCase())
    )

    if (attr) {
        const mult = multipliers.find(([key]) => key.toLowerCase() === attr.toLowerCase())

        if (mult) {
            return mult[1]
        }
    }

    console.error('Could not find meta data for nft', nft)
    throw new Error('Could not find meta data for nft')
}

export async function getNFTs(connection: Connection, owner: PublicKey) {
    try {
        const nftAccounts = await getParsedNftAccountsByOwner({
            publicAddress: owner.toBase58(),
            connection: connection,
        })
        console.log(nftAccounts)
        const nfts: NFTData[] = []
        for (const nft of nftAccounts) {
            try {
                const isSoliceNFT =
                    nftSymbols.includes(nft.data.symbol) &&
                    // Backend update auth
                    nft.updateAuthority === '9xPqH4wTQYSCnUKjZuzhxac6nfRiYXoHopCq5ge1K4nF'

                if (!isSoliceNFT) continue

                const metaData = await (await fetch(nft.data.uri)).json()
                const multiplier = getMultiplier(nft, metaData)

                nfts.push({
                    name: nft.data.name,
                    symbol: nft.data.symbol,
                    uri: metaData.image,
                    multiplier,
                    mint: nft.mint,
                })
            } catch (error) {
                console.error('failed to get nft metadata', nft, error)
                nfts.push({
                    name: nft.data.name,
                    symbol: nft.data.symbol,
                    mint: nft.mint,
                })
            }
        }

        return nfts
    } catch (error) {
        console.error(error)
        throw error
    }
}
